<template>
    <div class="d-flex h-100 align-items-center justify-content-center">
        <spinner-block v-if="groups.loading" />

        <div v-else-if="groups.isEmpty()">
            <h5>
                {{ $t('groups.errors.noGroups') }}
            </h5>
        </div>
    </div>
</template>

<script>
import {User} from '@/models/User';
import {Groups} from '@/models/vue-mc/Group';

export default {
    name: 'UsersGroupsIndex',
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    data() {
        return {
            groups: new Groups([], {
                routePrefix: this.user.getFetchURL(),
            }),
        };
    },
    async created() {
        await this.groups.page(1).fetch();

        if (!this.groups.isEmpty()) {
            this.redirect(this.groups.models[0]);
        }
    },
    methods: {
        redirect(group) {
            this.$router.replace(group.getLocation());
        },
    },
};
</script>
